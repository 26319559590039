import React, { createContext, useContext } from 'react';
import { getText } from '../utils/functions';
import { CompanyContext } from './company.context';
interface ILangContext {
  t: (value: any) => any;
  lang?: string;
}

interface ILangContextProvider {
  lang: string;
  children?: React.ReactNode;
}

export const LangContext = createContext<ILangContext>({
  t: () => {},
});

export const LangContextProvider = ({ lang, children }: ILangContextProvider) => {

  const getClient = useContext(CompanyContext)?.clientId || '0';

  const t = (data: any): object => getText(data.allI18NJson.nodes, lang, getClient);

  return (
    <LangContext.Provider
      value={{
        lang,
        t,
      }}
    >
      {children}
    </LangContext.Provider>
  );
};
